import React, { useState } from 'react';
import { DocumentListPotentialClients } from '../../Components/PotentialClients/DocumentList';
import {
  DocumentationStatusValidation,
  StepOne,
} from '../../types/PrekapiDocuments/PrekapiDocuments';
import { TableWarrantyPhotos } from '../../Components/PotentialClients/WarrantyPhotos/TableWarrantyPhotos';

interface WarrantyPhotosSectionProps {
  stepOne?: StepOne;
  claimId?: string;
  clientId?: string;
  setDataSteps: (steps?: DocumentationStatusValidation) => void;
}

export const WarrantyPhotosSection = ({
  stepOne,
  claimId,
  clientId,
  setDataSteps,
}: WarrantyPhotosSectionProps) => {
  const [isOnReview, setIsOnReview] = useState(false);

  return (
    <DocumentListPotentialClients
      title='Paso 1: Fotografias de la Garantía'
      onReview={setIsOnReview}
    >
      <TableWarrantyPhotos
        stepOne={stepOne}
        isOnReview={isOnReview}
        claimId={claimId}
        clientId={clientId}
        setDataSteps={setDataSteps}
      />
    </DocumentListPotentialClients>
  );
};
