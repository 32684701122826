import React, { createContext, ReactNode, useState } from 'react';

interface PrekapiFile {
  fileId?: string;
  fileName?: string;
  fileOneStatus?: string;
  fileTwoStatus?: string;
  fileThreeStatus?: string;
  fileFourStatus?: string;
  firstCommentary?: string;
  secondCommentary?: string;
  thirdCommentary?: string;
  fourthCommentary?: string;
}

interface CurrentPhoto {
  file?: string;
  alt?: string;
}

export interface ApproveRejectFileContextProps {
  showModal?: boolean;
  commentaries?: string;
  fileId?: string;
  containerText?: string;
  title?: string;
  buttonText?: string;
  warrantyPhotos?: PrekapiFile;
  creditBureau?: PrekapiFile;
  syntage?: PrekapiFile;
  whichFileIsBeingUpdated?: string;
  newStatus?: string;
  wasUpdated?: boolean;
  showFullImage: boolean;
  currentPhoto?: CurrentPhoto;
  isModalConclusion?: boolean;
  counterPhotosStatus: string[];
  setShowModal: (isShowed: boolean) => void;
  setCommentaries: (commentaries: string) => void;
  setFileId: (id: string) => void;
  setTitle: (title: string) => void;
  setContainerText: (text: string) => void;
  setButtonText: (text: string) => void;
  setWarrantyPhotos: (warrantyPhotos: PrekapiFile) => void;
  setCreditBureau: (creditBureau: PrekapiFile) => void;
  setSyntage: (syntage: PrekapiFile) => void;
  setWhichFileIsBeingUpdated: (fileIsBeingUploaded: string) => void;
  setNewStatus: (newStatus: string) => void;
  setWasUpdated: (wasUpdated: boolean) => void;
  setShowFullImage: (showFullImage: boolean) => void;
  setCurrentPhoto: (currentPhoto: CurrentPhoto) => void;
  setIsModalConclusion: (isModalConclusion: boolean) => void;
  setCounterPhotosStatus: (counterPhotosStatus: string[]) => void;
}

export const ApproveRejectFileContext =
  createContext<ApproveRejectFileContextProps>({
    showModal: false,
    commentaries: '',
    fileId: '',
    showFullImage: false,
    isModalConclusion: false,
    counterPhotosStatus: [],
    setFileId(_) {},
    setShowModal(_) {},
    setCommentaries(_) {},
    setButtonText(_) {},
    setContainerText(_) {},
    setTitle(_) {},
    setWarrantyPhotos(_) {},
    setCreditBureau(_) {},
    setSyntage(_) {},
    setWhichFileIsBeingUpdated(_) {},
    setNewStatus(_) {},
    setWasUpdated(_) {},
    setShowFullImage(_) {},
    setCurrentPhoto(_) {},
    setIsModalConclusion(_) {},
    setCounterPhotosStatus(_) {},
  });

export const ApproveRejectFileProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [commentaries, setCommentaries] = useState('');
  const [fileId, setFileId] = useState('');
  const [title, setTitle] = useState('');
  const [containerText, setContainerText] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [warrantyPhotos, setWarrantyPhotos] = useState<PrekapiFile>();
  const [creditBureau, setCreditBureau] = useState<PrekapiFile>();
  const [syntage, setSyntage] = useState<PrekapiFile>();
  const [whichFileIsBeingUpdated, setWhichFileIsBeingUpdated] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [wasUpdated, setWasUpdated] = useState(false);
  const [showFullImage, setShowFullImage] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState<CurrentPhoto>({});
  const [isModalConclusion, setIsModalConclusion] = useState(false);
  const [counterPhotosStatus, setCounterPhotosStatus] = useState<string[]>([]);
  const [handleMetaActionCancelButton, setHandleMetaActionCancelButton] =
    useState<() => {}>();
  const [handleMetaActionCustomButton, setHandleMetaActionCustomButton] =
    useState<() => {}>();
  return (
    <ApproveRejectFileContext.Provider
      value={{
        fileId,
        title,
        containerText,
        buttonText,
        showModal,
        warrantyPhotos,
        creditBureau,
        syntage,
        whichFileIsBeingUpdated,
        setWhichFileIsBeingUpdated,
        setWarrantyPhotos,
        setCreditBureau,
        setSyntage,
        setShowModal,
        commentaries,
        setCommentaries,
        setFileId,
        setTitle,
        setContainerText,
        setButtonText,
        setNewStatus,
        newStatus,
        wasUpdated,
        setWasUpdated,
        showFullImage,
        setShowFullImage,
        setCurrentPhoto,
        currentPhoto,
        setIsModalConclusion,
        isModalConclusion,
        counterPhotosStatus,
        setCounterPhotosStatus,
      }}
    >
      {children}
    </ApproveRejectFileContext.Provider>
  );
};
