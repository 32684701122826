import React, { useState } from 'react';
import { DocumentListPotentialClients } from '../../Components/PotentialClients/DocumentList';
import { TableFilesCreditBureau } from '../../Components/PotentialClients/FilesCreditBureau/TableFilesCreditBureau';
import { StepThree } from '../../types/PrekapiDocuments/PrekapiDocuments';

interface FileCreditBureautSectionProps {
  stepTwo?: StepThree;
}

export const FileCreditBureautSection = ({
  stepTwo,
}: FileCreditBureautSectionProps) => {
  const [isOnReview, setIsOnReview] = useState(false);
  return (
    <DocumentListPotentialClients
      title='Paso 2: Información del Búro de Crédito'
      onReview={setIsOnReview}
    >
      <TableFilesCreditBureau stepTwo={stepTwo} isOnReview={isOnReview} />
    </DocumentListPotentialClients>
  );
};
