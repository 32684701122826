import React, { useContext, useEffect, useState } from 'react';
import { Button, TableCell, TableRow, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getObjectPrekapiDocs } from '../../Utils/AWS';
import { ApproveRejectFileUI } from '../../types/PrekapiDocuments/PrekapiDocumentsUI';
import {
  PrekapiDocumentStates,
  TypeFilePrekapi,
} from '../../Constants/PotentialClients';
import { LabelApproveReject } from './LabelApproveReject';
import { ApproveRejectFileContext } from '../../contexts/PotentialClients/ApproveRejectFileContext';
import { ShowFileAnother } from './ShowFileAnother';
import { AuthRole } from '../../Utils/enums';
import { isDate } from '../../Utils/DateUtils';
import { AuthRoles } from '../../Constants/AuthRoles';
import { isSentStepThree } from '../../Utils/PotentialClients/StepsFiles';

interface DataTableStepProps extends ApproveRejectFileUI {
  id?: string;
  loadedDate?: string;
  contentUrl?: string;
  comments?: string;
  isOnReview?: boolean;
  status?: string;
  typeFilePrekapi?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    delete: {
      backgroundColor: '#FA4032',
      color: 'white',
    },
    general: {
      marginLeft: '5px',
      marginRight: '5px',
    },
  })
);

export const DataTableStep = ({
  id,
  loadedDate,
  contentUrl,
  comments,
  isOnReview = false,
  status,
  showOnApprove,
  showOnReject,
  typeFilePrekapi = TypeFilePrekapi.Photos,
}: DataTableStepProps) => {
  const [signedUrl, setSignedUrl] = useState('');
  const [statusTracker, setStatusTracker] = useState(status);
  const { setFileId } = useContext(ApproveRejectFileContext);
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const [hasPermissionChangeStatus, setHasPermissionChangeStatus] =
    useState(false);
  useEffect(() => {
    if (
      contentUrl &&
      (typeFilePrekapi === TypeFilePrekapi.Photos ||
        typeFilePrekapi === TypeFilePrekapi.Bureau)
    ) {
      getObjectPrekapiDocs(contentUrl)
        .then((url) => {
          setSignedUrl(url);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentUrl]);
  useEffect(() => {
    setStatusTracker(status);
  }, [status]);
  useEffect(() => {
    setHasPermissionChangeStatus(
      [AuthRole.Admin, AuthRoles.Agent].includes(user.role.id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuthRole, user]);
  return (
    <TableRow>
      <TableCell rowSpan={1}>
        <ShowFileAnother
          file={signedUrl}
          metaData={loadedDate}
          typeFilePrekapi={typeFilePrekapi}
        />
      </TableCell>
      <TableCell align='center' rowSpan={1}>
        <Typography variant='body2'>{isDate(loadedDate)}</Typography>
      </TableCell>
      <TableCell align='center' rowSpan={1}>
        <Typography variant='body2'>{comments}</Typography>
      </TableCell>
      <TableCell rowSpan={1}>
        {hasPermissionChangeStatus &&
          (PrekapiDocumentStates.Received === statusTracker ||
          isSentStepThree(statusTracker || '', typeFilePrekapi) ? (
            <>
              <Button
                variant='contained'
                disabled={!isOnReview}
                className={`${classes.delete} ${classes.general}`}
                onClick={() => {
                  showOnReject?.(true);
                  setFileId(id || '');
                }}
              >
                Rechazar
              </Button>
              <Button
                variant='contained'
                className={classes.general}
                disabled={!isOnReview}
                onClick={() => {
                  showOnApprove?.(true);
                  setFileId(id || '');
                }}
              >
                Aprobar
              </Button>
            </>
          ) : (
            <LabelApproveReject status={statusTracker} />
          ))}
      </TableCell>
    </TableRow>
  );
};
