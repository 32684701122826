import React, { useContext } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { ApproveRejectFileContext } from '../../contexts/PotentialClients/ApproveRejectFileContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: '10px',
      width: '80%',
      height: '80%',
    },
    cardMedia: {
      width: '100%',
    },
  })
);

export const ShowFullPhoto = () => {
  const classes = useStyles();
  const { showFullImage, setShowFullImage, currentPhoto, setCurrentPhoto } =
    useContext(ApproveRejectFileContext);

  const handleClose = () => {
    setShowFullImage(false);
    setCurrentPhoto({
      file: '',
      alt: '',
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={showFullImage}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showFullImage}>
          <div className={classes.paper}>
            <img
              src={currentPhoto?.file}
              alt={currentPhoto?.alt}
              width='100%'
              height='100%'
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default function TransitionsModal() {}
