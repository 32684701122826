import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import {
  QualificationInformationModel,
  TranslateEmailStatus,
} from '../../../Utils/models/ClientInformationModel';
import { Button } from '@material-ui/core';
import { PreapproveApplication } from './PreapproveApplication';
import { AuthRoles } from '../../../Constants/AuthRoles';
import { AuthRole } from '../../../Utils/enums';
import { allStepsAreApproved } from '../../../Utils/PotentialClients/StepsFiles';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 12,
  },
  pos: {
    marginBottom: 12,
  },
  noShowButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'default',
    padding: 0,
    marginLeft: 0,
    marginBottom: 0,
    color: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'default',
      padding: 0,
      marginLeft: 0,
      marginBottom: 0,
      color: 'transparent',
      boxShadow: 'none',
    },
  },
});

export default function OutlinedCard(
  properties: QualificationInformationModel
) {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [hasPermissionChangeStatus, setHasPermissionChangeStatus] =
    useState(false);
  const title = properties.title;
  const propertiesList = properties.properties;
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const [allApproved, setAllApproved] = useState(false);
  useEffect(() => {
    setHasPermissionChangeStatus(
      [AuthRole.Admin, AuthRoles.Agent, AuthRole.Lead].includes(user.role.id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuthRole, user]);
  useEffect(() => {
    setAllApproved(allStepsAreApproved(properties.rawData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties]);

  return (
    <>
      <PreapproveApplication
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <Card className={classes.root} variant='outlined'>
        <CardContent>
          <Typography variant='h4' component='h2'>
            {title}
          </Typography>
          <p />
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            spacing={1}
            xs={12}
          >
            <Grid item xs={5}>
              {propertiesList.map((itemMap, index) => {
                return (
                  <Typography
                    key={index}
                    className={classes.pos}
                    color='textSecondary'
                  >
                    {itemMap.name}:
                  </Typography>
                );
              })}
            </Grid>
            <Grid item xs={7}>
              {propertiesList.map((itemMap, index) => {
                return (
                  <Typography key={index} className={classes.pos}>
                    {itemMap.value}
                  </Typography>
                );
              })}
            </Grid>

            <Grid item xs={5}></Grid>
            <Grid item xs={7}>
              {properties.hasButton &&
              hasPermissionChangeStatus &&
              allApproved &&
              !properties.isPreapprovedClient ? (
                <Button
                  variant='contained'
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Preaprobar Solicitud
                </Button>
              ) : (
                <div
                  style={{
                    height: '36px',
                  }}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
