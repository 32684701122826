import Cookies from 'src/Utils/cookies';

interface ChangeToPreapproveClientFromPotencial {
  clientId?: string;
  clientUuid?: string;
}

export async function changeToPreapproveClientFromPotencial({
  clientId,
  clientUuid,
}: ChangeToPreapproveClientFromPotencial) {
  const token = Cookies.getCookie('token');
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/clientes/to/preapprove-client`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ clientId }),
    }
  );
  const data = await request.json();
  if (!request.ok) {
    throw new Error('No se pudo efectuar el cambio, intente de nuevo.');
  }
  return data;
}
