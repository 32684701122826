import React from 'react';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import IconButton from '@material-ui/core/IconButton';
import { CircularProgress } from '@material-ui/core';

interface ShowFileBureauProps {
  urlFile?: string;
}

export const ShowFileBureau = ({ urlFile }: ShowFileBureauProps) => {
  const [loading, setLoading] = React.useState(false);
  const handleOpen = async () => {
    setLoading(true);
    try {
      if (!urlFile) {
        return;
      }
      const request = await fetch(urlFile);
      const blob = await request.blob();
      const parsedBlob = new Blob([blob], { type: 'application/pdf' });
      const createdObjectFromBlob = window.URL.createObjectURL(parsedBlob);
      window.open(createdObjectFromBlob, '_blank');
    } catch (error) {
      console.error({ error });
    }
    setLoading(false);
  };

  return (
    <>
      <IconButton disabled={loading} onClick={handleOpen}>
        {loading ? <CircularProgress size={24} /> : <InsertDriveFileIcon />}
      </IconButton>
    </>
  );
};
