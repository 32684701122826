import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TableFooter, Typography } from '@material-ui/core';

interface FilesTablePotentialClientsProps {
  children: ReactNode;
  showFooter?: boolean;
  validationResult?: string;
  commentaries?: string;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const FilesTablePotentialClients = ({
  children,
  showFooter = false,
  commentaries,
  validationResult,
}: FilesTablePotentialClientsProps) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell> </TableCell>
            <TableCell align='center'>Fecha de carga</TableCell>
            <TableCell align='center'>Comentarios</TableCell>
            <TableCell align='center'> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
        {showFooter && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={5} align='center'></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' colSpan={5}>
                <Typography
                  component='div'
                  dangerouslySetInnerHTML={{
                    __html: `<div>Resultado de la validación: ${validationResult}</div>`,
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' colSpan={5}>
                <Typography
                  component='div'
                  dangerouslySetInnerHTML={{
                    __html: `<div>Comentarios: ${commentaries}</div>`,
                  }}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};
