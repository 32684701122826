import React, { useContext, useEffect } from 'react';
import { FilesTablePotentialClients } from '../FilesTable';
import { DataTableStep } from '../DataTableStep';
import { StepThree } from '../../../types/PrekapiDocuments/PrekapiDocuments';
import { ApproveRejectFileContext } from '../../../contexts/PotentialClients/ApproveRejectFileContext';
import {
  PrekapiDocumentStates,
  TypeFilePrekapi,
} from '../../../Constants/PotentialClients';
import { ApproveRejectFileModal } from '../ApproveRejectFileModal';

interface TableFilesCreditBureauProps {
  stepTwo?: StepThree;
  isOnReview?: boolean;
}

export const TableFilesCreditBureau = ({
  stepTwo,
  isOnReview = false,
}: TableFilesCreditBureauProps) => {
  const {
    creditBureau,
    setShowModal,
    setButtonText,
    setContainerText,
    setTitle,
    setNewStatus,
    setCreditBureau,
  } = useContext(ApproveRejectFileContext);
  useEffect(() => {
    setCreditBureau({
      firstCommentary: stepTwo?.commentaries,
      fileOneStatus: stepTwo?.status,
    });
  }, [stepTwo?.commentaries, stepTwo?.status]);
  return (
    <div style={{ marginTop: '10px' }}>
      {/* <ApproveRejectFileModal open={showModal} /> */}
      <FilesTablePotentialClients>
        <DataTableStep
          id={stepTwo?.id}
          comments={stepTwo?.commentaries}
          typeFilePrekapi={TypeFilePrekapi.Bureau}
          loadedDate={stepTwo?.uploadDate?.toString()}
          contentUrl={stepTwo?.url}
          isOnReview={isOnReview}
          showOnReject={(isReject) => {
            setShowModal(isReject);
            setTitle('Paso 2: Informe del Buró de Crédito');
            setContainerText(
              `¿Está seguro de que desea rechazar el archivo ${
                stepTwo?.fileName ?? ''
              }?`
            );
            setButtonText('Rechazar');
            setNewStatus(PrekapiDocumentStates.Rejected);
          }}
          showOnApprove={(isApprove) => {
            setShowModal(isApprove);
            setTitle('Paso 2: Informe del Buró de Crédito');
            setContainerText(
              `¿Está seguro de que desea aprobar el archivo ${
                stepTwo?.fileName ?? ''
              }?`
            );
            setButtonText('Aprobar');
            setNewStatus(PrekapiDocumentStates.Approved);
          }}
          status={creditBureau?.fileOneStatus}
        />
      </FilesTablePotentialClients>
    </div>
  );
};
