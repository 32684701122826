export const PrekapiDocumentStates = {
  Approved: 'approved',
  Sent: 'sent',
  Rejected: 'rejected',
  Received: 'received',
  OnReview: 'onreview',
  InRevision: 'inrevision',
  ToSend: 'tosend',
  Error: 'error',
};

export const WhichFileState = {
  PhotoFileOne: 'photo_one',
  PhotoFileTwo: 'photo_two',
  PhotoFileThree: 'photo_three',
  PhotoFileFour: 'photo_four',
  BureauFileOne: 'bureau_one',
  SyntageFileOne: 'syntage_one',
};

export const TypeFilePrekapi = {
  Photos: 'photos',
  Bureau: 'bureau',
  Syntage: 'syntage',
};
