import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

import { makeStyles } from '@material-ui/core';
import ListActions from '../../Components/ListActions';
import CustomList from '../../Components/CustomList';
import { AuthRole, Position } from '../../Utils/enums';
import { UpdateLink } from '../../Components/PotentialClients/UpdateLink/UpdateLink';
import { parse } from 'query-string';
import { getFilterForPreApprovedOrPrequalifyByParam } from '../../Utils/PotentialClients/Filters';

const useStyles = makeStyles({
  small: {
    width: '120px',
  },
  medium: {
    width: '200px',
  },
});

const PersonFilter = (props) => {
  const classes = useStyles(props);

  return (
    <Filter {...props} variant='outlined'>
      <TextInput
        source='id'
        label='Id'
        className={classes.small}
        resettable
        alwaysOn
      />
      <TextInput
        source='q'
        label='resources.people.fields.firstName'
        className={classes.medium}
        resettable
        alwaysOn
      />
      <TextInput source='leadSource' label='Fuente' resettable />
      {props.employeeData.position !== Position.SalesAdvisor && (
        <ReferenceInput
          reference='agents'
          className={classes.medium}
          source='agent'
          label='Asesor de Ventas'
          resettable
        >
          <SelectInput />
        </ReferenceInput>
      )}
    </Filter>
  );
};

export const PotencialClientList = ({ permissions, ...props }) => {
  const [hasPermissionChangeStatus, setHasPermissionChangeStatus] =
    useState(false);
  const employee = JSON.parse(localStorage.getItem('employee') || '{}');
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { isPreapprovedClient } = parse(props.location.search);
  const [isPotencialClient, setIsPotencialClient] = useState(false);
  const [filterClients, setFilterClients] = useState({
    isPreapprovedClient: false,
  });
  useEffect(() => {
    setHasPermissionChangeStatus(
      [AuthRole.Admin, AuthRole.DigitalSale].includes(user.role.id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuthRole, user]);
  useEffect(() => {
    const gottenFilter =
      getFilterForPreApprovedOrPrequalifyByParam(isPreapprovedClient);
    setFilterClients(gottenFilter);
    setIsPotencialClient(!gottenFilter.isPreapprovedClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreapprovedClient]);

  return (
    <CustomList
      filters={<PersonFilter employeeData={employee} />}
      filterDefaultValues={filterClients}
      actions={
        <ListActions entity='person' permissions={permissions} {...props} />
      }
      basePath='/clientes'
      {...props}
    >
      <Datagrid rowClick='show'>
        <TextField source='id' />
        <TextField source='phone' label='Teléfono' />
        <TextField source='email' label='Email' />
        <TextField source='name' label='Nombre' />
        <TextField source='personType' label='Tipo de Persona' />
        <TextField source='leadSource' label='Fuente' />
        <FunctionField
          source='agent'
          emptyText='-'
          label='Asesor de ventas'
          render={(record) =>
            record?.agentName ? `${record?.agentName ?? ''}` : '-'
          }
        ></FunctionField>
        {isPotencialClient && (
          <FunctionField
            source='expirationDate'
            label='Renovar Link'
            render={(record) =>
              hasPermissionChangeStatus ? (
                new Date(record?.expirationDate) < new Date() ? (
                  <UpdateLink
                    expirationDate={record?.expirationDate}
                    claimId={record?.claimId}
                  />
                ) : null
              ) : null
            }
          ></FunctionField>
        )}
      </Datagrid>
    </CustomList>
  );
};

export default PotencialClientList;
