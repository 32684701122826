import { Grid, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { ActionsFiles } from './ActionsFiles';

interface DocumentListPotentialClientsProps {
  title: string;
  actions?: ReactNode;
  children: ReactNode;
  onReview?: (isOnReview: boolean) => void;
}

export const DocumentListPotentialClients = ({
  title,
  children,
  actions,
  onReview,
}: DocumentListPotentialClientsProps) => {
  return (
    <Grid
      continer
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={2}
      style={{
        marginTop: '20px',
      }}
    >
      <Grid
        container
        item
        xs={12}
        style={{
          marginBottom: '30px',
        }}
      >
        <Grid item xs={10}>
          <Typography>{title}</Typography>
        </Grid>
        <Grid xs={2}>
          <ActionsFiles onReview={onReview} />
        </Grid>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};
