import React, { useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ApproveRejectFileContext } from '../../contexts/PotentialClients/ApproveRejectFileContext';

interface ClickOnPhotoProps {
  file?: string;
  alt?: string;
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    baseImage: {
      width: '100px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    general: {
      marginLeft: '5px',
      marginRight: '5px',
    },
  })
);

export const ClickOnPhoto = ({ file, alt }: ClickOnPhotoProps) => {
  const classes = useStyles();
  const { setShowFullImage, setCurrentPhoto } = useContext(
    ApproveRejectFileContext
  );
  const handleClickOnPhoto = () => {
    setShowFullImage(true);
    setCurrentPhoto({
      file,
      alt,
    });
  };
  return (
    <img
      src={file}
      alt={alt}
      className={classes.baseImage}
      onClick={handleClickOnPhoto}
    />
  );
};
