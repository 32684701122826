import React from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import authProvider from './Providers/authProvider';
import dataProvider from './Providers/dataProvider';
import spanishMessages from './i18n/es';

import AdminLayout from './layouts/AdminLayout';

import useSettings from './hooks/useSettings';
import { createTheme } from './Theme';

import {
  academicLevels,
  addressTypes,
  applicationReasons,
  applicationSource,
  applicationTypes,
  agents,
  assets,
  assetsType,
  banks,
  bureaus,
  clientSource,
  contactType,
  dashboard,
  departments,
  documentTypes,
  economicActivities,
  employees,
  franchises,
  guarantees,
  incomes,
  incomeTypes,
  loanApplications,
  maritalStatuses,
  occupations,
  moralPeople,
  people,
  positions,
  potencialClient,
  professions,
  promotions,
  references,
  referenceTypes,
  related,
  relatedTypes,
  stateRules,
  substates,
  users,
  notaries,
  evaluationUnits,
  blacklists,
  guaranteePanel,
  loanReferences,
} from './Views';
import customRoutes from './customRoutes';
import CustomLogin from './Components/CustomLogin';
import PreapprovedClient from './Views/PreapprovedClients';

const messages = {
  es: { ...spanishMessages },
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'es', {
  allowMissing: true,
});

const App: React.ReactFragment = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <Admin
      dashboard={dashboard}
      loginPage={CustomLogin}
      dataProvider={dataProvider}
      customRoutes={customRoutes}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      layout={AdminLayout}
      theme={theme}
    >
      {() => {
        return [
          <Resource name='academic-levels' {...academicLevels} />,
          <Resource name='address-types' {...addressTypes} />,
          <Resource name='agents' {...agents} />,
          <Resource name='application-reasons' {...applicationReasons} />,
          <Resource name='application-sources' {...applicationSource} />,
          <Resource name='application-types' {...applicationTypes} />,
          <Resource name='asset-type-parameters' />,
          <Resource name='asset-types' {...assetsType} />,
          <Resource name='assets' {...assets} />,
          <Resource name='banks' {...banks} />,
          <Resource name='blacklist-dictums' />,
          <Resource name='blacklists' {...blacklists} />,
          <Resource name='cities' />,
          <Resource name='client-sources' {...clientSource} />,
          <Resource name='companies' />,
          <Resource name='contact-histories' />,
          <Resource name='contact-history-types' />,
          <Resource name='contact-types' {...contactType} />,
          <Resource name='contacts' />,
          <Resource name='countries' />,
          <Resource name='credit-inquiry-unykoos' {...bureaus} />,
          <Resource name='data-types' />,
          <Resource name='departments' {...departments} />,
          <Resource name='departments/zone' />,
          <Resource name='document-segments' />,
          <Resource name='document-types' {...documentTypes} />,
          <Resource name='documents' />,
          <Resource name='economic-activities' {...economicActivities} />,
          <Resource name='employees' {...employees} />,
          <Resource name='employees/available-people' />,
          <Resource name='evaluation-units' {...evaluationUnits} />,
          <Resource name='franchises' {...franchises} />,
          <Resource name='franchises/available-people' />,
          <Resource name='funding-companies' />,
          <Resource name='guarantees/panel' {...guaranteePanel} />,
          <Resource name='guarantees' {...guarantees} />,
          <Resource name='income-types' {...incomeTypes} />,
          <Resource name='incomes' {...incomes} />,
          <Resource name='legal-people' {...moralPeople} />,
          <Resource name='loan-application-timelines' />,
          <Resource name='loan-applications' {...loanApplications} />,
          <Resource name='loan-references' {...loanReferences} />,
          <Resource name='marital-statuses' {...maritalStatuses} />,
          <Resource name='municipalities' />,
          <Resource name='neighborhoods' />,
          <Resource name='notaries' {...notaries} />,
          <Resource name='occupations' {...occupations} />,
          <Resource name='operators' />,
          <Resource name='parameters' />,
          <Resource name='people' {...people} />,
          <Resource name='permitted-states' />,
          <Resource name='positions' {...positions} />,
          <Resource name='clientes' {...potencialClient} />,
          <Resource name='preapproved-clients' {...PreapprovedClient} />,
          <Resource name='prekapi-documents' />,
          <Resource name='professions' {...professions} />,
          <Resource name='promotions' {...promotions} />,
          <Resource name='promotions/active' />,
          <Resource name='provinces' />,
          <Resource name='rating' />,
          <Resource name='reference-types' {...referenceTypes} />,
          <Resource name='references' {...references} />,
          <Resource name='related-documents' />,
          <Resource name='related-types' {...relatedTypes} />,
          <Resource name='relateds' {...related} />,
          <Resource name='state-rules' {...stateRules} />,
          <Resource name='state-types' />,
          <Resource name='states' />,
          <Resource name='substates' {...substates} />,
          <Resource name='timelines' />,
          <Resource name='user-types' />,
          <Resource name='users' {...users} />,
        ];
      }}
    </Admin>
  );
};

export default App;
