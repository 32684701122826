import React, { useEffect, useState } from 'react';
import { Show, SimpleShowLayout, useRecordContext } from 'react-admin';
import { PotentialClientsActions } from '../../Components/PotentialClients/Toolbar/PotentialClientsActions';
import { ApproveRejectContainer } from '../../Components/PotentialClients/ApproveRejectContainer';
import { StatusResume } from '../../types/PrekapiDocuments/PrekapiDocuments';
import { getResumeStatus } from '../../Services/PrekapiDocuments';

const ContextPersonShow = () => {
  const record = useRecordContext();
  const [resumeData, setResume] = useState<StatusResume>();
  const [statusesWasUpdated, setStatusesWasUpdated] = useState(false);
  useEffect(() => {
    getResumeStatus(record.id.toString())
      .then((data) => {
        setResume(data?.statusResume);
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (statusesWasUpdated) {
      getResumeStatus(record.id.toString())
        .then((data) => {
          setResume(data?.statusResume);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesWasUpdated]);

  return (
    <>
      <PotentialClientsActions
        informationClient={record}
        resumeData={resumeData}
      />
      <ApproveRejectContainer
        claimId={record.claimId}
        clientId={record.clientId}
        handleWasUpdatedSteps={setStatusesWasUpdated}
      />
    </>
  );
};
const PotencialClientShow = ({ ...props }) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ContextPersonShow />
      </SimpleShowLayout>
    </Show>
  );
};

export default PotencialClientShow;
