export function isDate(value: any): String {
    // Convertir el valor a string
    const stringValue = String(value);

    // Intentar crear un objeto Date
    const date = new Date(stringValue);

    // Validar si el objeto Date es válido
    if (!isNaN(date.getTime())) {
        // Dividir el string en partes (año, mes, día)
        const [year, month, day] = value.split('-');

        // Mapa de meses
        const months = [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
        ];

        // Convertir el mes a texto
        const monthIndex = parseInt(month, 10) - 1; // Los índices son 0-based
        const monthName = months[monthIndex];

        // Retornar el formato en texto
        return `${parseInt(day, 10)} de ${monthName} de ${year}`;
    }
    return value;
}