import React, { useContext, useEffect, useState } from 'react';
import {
  ApproveRejectFileContext,
  ApproveRejectFileProvider,
} from '../../contexts/PotentialClients/ApproveRejectFileContext';
import { ApproveRejectFileModal } from './ApproveRejectFileModal';
import { WarrantyPhotosSection } from '../../Sections/PotentialClients/WarrantyPhotos';
import { getDocumentationStatusValidation } from '../../Services/PrekapiDocuments';
import { FileCreditBureautSection } from '../../Sections/PotentialClients/FileCreditBureauSection';
import { FilesSyntageSection } from '../../Sections/PotentialClients/FilesSyntageSection';
import { DocumentationStatusValidation } from '../../types/PrekapiDocuments/PrekapiDocuments';

interface ApproveRejectWithModalProps {
  clientId?: string;
  claimId?: string;
  handleWasUpdatedSteps: (wasUpdated: boolean) => void;
}

const ApproveRejectWithModal = ({
  claimId,
  clientId,
  handleWasUpdatedSteps,
}: ApproveRejectWithModalProps) => {
  const { fileId, showModal, setShowModal, title, buttonText, containerText } =
    useContext(ApproveRejectFileContext);
  const [dataSteps, setDataSteps] = useState<DocumentationStatusValidation>();
  const resolvePromise = async () => {
    try {
      const steps = await getDocumentationStatusValidation(
        clientId || '',
        claimId || ''
      );
      setDataSteps(steps);
    } catch (error) {
      console.error({ error });
    }
  };
  useEffect(() => {
    resolvePromise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    handleWasUpdatedSteps(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSteps]);
  return (
    <>
      <ApproveRejectFileModal
        id={fileId}
        open={showModal}
        title={title}
        text={containerText}
        textActionButton={buttonText}
        setOpen={setShowModal}
        claimId={claimId}
        clientId={clientId}
        setDataSteps={(steps) => {
          handleWasUpdatedSteps(false);
          setDataSteps(steps);
        }}
      />
      <WarrantyPhotosSection
        stepOne={dataSteps?.step_one}
        setDataSteps={(steps) => {
          handleWasUpdatedSteps(false);
          setDataSteps(steps);
        }}
        claimId={claimId}
        clientId={clientId}
      />
      <FileCreditBureautSection stepTwo={dataSteps?.step_two} />
      <FilesSyntageSection stepThree={dataSteps?.step_three} />
    </>
  );
};

export const ApproveRejectContainer = ({
  claimId,
  clientId,
  handleWasUpdatedSteps,
}: ApproveRejectWithModalProps) => {
  return (
    <ApproveRejectFileProvider>
      <ApproveRejectWithModal
        claimId={claimId}
        clientId={clientId}
        handleWasUpdatedSteps={handleWasUpdatedSteps}
      />
    </ApproveRejectFileProvider>
  );
};
