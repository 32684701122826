import React from 'react';
import {
  Button,
  Divider,
  Fade,
  Grid,
  Modal,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { changeToPreapproveClientFromPotencial } from '../../../Services/PotencialClients';
import { useRecordContext } from 'ra-core';

interface PreapproveApplicationProps {
  showModal?: boolean;
  setShowModal?: (show: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      paddingTop: '10px',
      paddingBottom: '10px',
      // padding: theme.spacing(2, 4, 3),
    },
    paddings: {
      padding: theme.spacing(1, 4, 1, 4),
    },
    textBox: {
      width: '100%',
      margin: '10px auto',
    },
  })
);

export const PreapproveApplication = ({
  showModal = false,
  setShowModal,
}: PreapproveApplicationProps) => {
  const classes = useStyles();
  const record = useRecordContext();
  const handlePreapproveClient = async () => {
    try {
      await changeToPreapproveClientFromPotencial({
        clientId: `${record.id}`,
      });
      setShowModal?.(false);
    } catch (error) {
      console.error({ error });

      setShowModal?.(false);
    }
  };
  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={showModal}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        <div className={classes.paper}>
          <Typography
            id='transition-modal-title'
            variant='h4'
            component='h2'
            color='textPrimary'
            className={classes.paddings}
          >
            Preaprobación
          </Typography>
          <Divider variant='fullWidth' />
          <div
            style={{
              marginTop: '25px',
              marginBottom: '25px',
            }}
          >
            <Typography
              id='transition-modal-description'
              variant='h3'
              align='center'
              className={classes.paddings}
              color='textPrimary'
            >
              ¿Está seguro de que desea preaprobar esta solicitud?
            </Typography>
            <Typography
              id='transition-modal-description'
              variant='h4'
              align='center'
              className={classes.paddings}
              color='textPrimary'
            >
              Al realizar el cambio, no se visualizará más en la lista de
              Clientes Precalificados.
            </Typography>
          </div>
          <Divider variant='fullWidth' />
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='flex-end'
            spacing={2}
            style={{
              marginTop: '10px',
            }}
          >
            <Grid item>
              <Button
                style={{ background: '#FA4032' }}
                onClick={() => {
                  setShowModal?.(false);
                }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  handlePreapproveClient().catch((err) => {
                    console.error({ err });
                  });
                }}
                variant='contained'
              >
                Preaprobar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};
