import {
  DocumentationStatusValidation,
  StatusResume,
} from 'src/types/PrekapiDocuments/PrekapiDocuments';
import Cookies from '../../Utils/cookies';

interface UpdatePrekapiDocument {
  id?: string;
  status?: string;
  commentaries?: string;
}

export async function updatePrekapiDocument({
  id,
  status,
  commentaries,
}: UpdatePrekapiDocument) {
  const token = Cookies.getCookie('token');
  const requestUpdate = await fetch(
    `${process.env.REACT_APP_API_URL}/prekapi-documents/status/${id}`,
    {
      method: 'PUT',
      body: JSON.stringify({ status, commentaries }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!requestUpdate.ok)
    throw new Error(
      'No se pudo cambiar el estado de validación, por favor inténtelo más tarde.'
    );
}

export async function getDocumentationStatusValidation(
  clientId: string,
  claimId: string
) {
  const token = Cookies.getCookie('token');
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Content-Type', 'application/json');
  const url = `${process.env.REACT_APP_API_URL}/prekapi-documents/${clientId}/${claimId}`;
  const request = await fetch(url, {
    cache: 'reload',
    headers,
  });

  const data = await request.json();

  if (!request.ok) throw new Error(data);
  const parsedData: DocumentationStatusValidation = data;
  return parsedData;
}

export async function getResumeStatus(clientId: string) {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/prekapi-documents/${clientId}`
  );

  const data = await request.json();
  if (!request.ok) throw new Error(data);

  const parsedData: StatusResume = data;
  return parsedData;
}

export async function approveAllStepOne(
  id: string,
  commentaries: string,
  claimId: string | undefined
) {
  const token = Cookies.getCookie('token');
  const requestUpdate = await fetch(
    `${process.env.REACT_APP_API_URL}/prekapi-documents/approve/conclusion`,
    {
      method: 'PUT',
      body: JSON.stringify({ id, commentaries, claimuuId: claimId }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!requestUpdate.ok)
    throw new Error(
      'No se pudo cambiar el estado de validación, por favor inténtelo más tarde.'
    );
}
