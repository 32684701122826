import React, { useState } from 'react';
import { DocumentListPotentialClients } from '../../Components/PotentialClients/DocumentList';
import { StepThree } from '../../types/PrekapiDocuments/PrekapiDocuments';
import { TableFilesSyntage } from '../../Components/PotentialClients/Syntage/TableFilesSyntage';

interface FilesSyntageSectionProps {
  stepThree?: StepThree;
}

export const FilesSyntageSection = ({
  stepThree,
}: FilesSyntageSectionProps) => {
  const [isOnReview, setIsOnReview] = useState(false);
  return (
    <DocumentListPotentialClients
      title='Paso 3: Syntage'
      onReview={setIsOnReview}
    >
      <TableFilesSyntage stepThree={stepThree} isOnReview={isOnReview} />
    </DocumentListPotentialClients>
  );
};
