import React from 'react';
import { TypeFilePrekapi } from '../../Constants/PotentialClients';
import { Typography } from '@material-ui/core';
import { ClickOnPhoto } from './ClickOnPhoto';
import { ShowFileBureau } from './FilesCreditBureau/ShowFileBureau';

interface ShowFileAnotherProps {
  file?: any;
  typeFilePrekapi?: string;
  metaData?: string;
}

export const ShowFileAnother = ({
  file,
  typeFilePrekapi,
  metaData,
}: ShowFileAnotherProps) => {
  if (typeFilePrekapi === TypeFilePrekapi.Photos) {
    return <ClickOnPhoto file={file} alt={metaData} />;
  }
  if (typeFilePrekapi === TypeFilePrekapi.Syntage) {
    return (
      <Typography variant='body2'>
        El cliente confirmó haber concluido el proceso.
      </Typography>
    );
  }
  return <ShowFileBureau urlFile={file} />;
};
