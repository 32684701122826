import {
  PrekapiDocumentStates,
  TypeFilePrekapi,
} from 'src/Constants/PotentialClients';
import {
  Conclusion,
  StepOne,
  StepThree,
} from 'src/types/PrekapiDocuments/PrekapiDocuments';

interface HowManyAreThereInStepOne {
  itsOk: boolean;
  status: string;
  statusCode: string;
  rejectedCounter: number;
  approvedCounter: number;
  receivedCounter: number;
  noStatusCounters: number;
}

export function howManyAreThereInStepOne(
  stepOne?: StepOne
): HowManyAreThereInStepOne {
  const statuses = [
    stepOne?.firstFile?.status,
    stepOne?.secondFile?.status,
    stepOne?.thirdFile?.status,
    stepOne?.fourthFile?.status,
  ];

  let rejected = 0;
  let approved = 0;
  let received = 0;
  statuses.forEach((status) => {
    if (status === PrekapiDocumentStates.Approved) {
      approved += 1;
    }
    if (status === PrekapiDocumentStates.Rejected) {
      rejected += 1;
    }
    if (status === PrekapiDocumentStates.Received) {
      received += 1;
    }
  });
  const totalApprovedAndRejected = approved + rejected;
  if (totalApprovedAndRejected < 4 || received === 4) {
    return {
      itsOk: true,
      status: '<p style="color: white; font-weight: bold;">En Revisión</p>',
      statusCode: PrekapiDocumentStates.Received,
      approvedCounter: approved,
      rejectedCounter: rejected,
      receivedCounter: received,
      noStatusCounters: approved + rejected,
    };
  }

  if (rejected >= 1) {
    return {
      itsOk: true,
      status: '<p style="color: #fa4032; font-weight: bold;">Rechazado</p>',
      statusCode: PrekapiDocumentStates.Rejected,
      approvedCounter: approved,
      rejectedCounter: rejected,
      receivedCounter: received,
      noStatusCounters: approved + rejected,
    };
  }
  return {
    itsOk: false,
    status: '<p style="color: #5cb338; font-weight: bold;">Aprobado</p>',
    statusCode: PrekapiDocumentStates.Approved,
    approvedCounter: approved,
    rejectedCounter: rejected,
    receivedCounter: received,
    noStatusCounters: approved + rejected,
  };
}

export function getLastChanged(stepOne?: StepOne): StepThree | undefined {
  if (stepOne === undefined) {
    return undefined;
  }
  const gottenValues = Object.values(stepOne);
  const sortedValues: StepThree[] = gottenValues.sort(function (a, b) {
    const aDate = new Date();
    const bDate = new Date();
    const minusDates = aDate.getTime() - bDate.getTime();
    return minusDates;
  });

  const [first, second] = sortedValues;
  if (
    first.status === PrekapiDocumentStates.Received ||
    second.status === PrekapiDocumentStates.Received
  )
    return undefined;
  return first;
}

export function canShowModal(conclusion?: Conclusion) {
  if (conclusion === undefined) return false;

  if (
    conclusion?.status === PrekapiDocumentStates.Approved ||
    conclusion?.status === PrekapiDocumentStates.Rejected
  ) {
    return false;
  }
  return true;
}

export function isSentStepThree(status: string, typeFilePrekapi?: string) {
  const isValidForStepThree =
    typeFilePrekapi === TypeFilePrekapi.Syntage &&
    status === PrekapiDocumentStates.Sent;

  return isValidForStepThree;
}

export function allStepsAreApproved(stepData: any) {
  if (!stepData?.stepOne || !stepData?.stepTwo || !stepData?.stepThree) {
    return false;
  }

  if (
    stepData?.stepOne === PrekapiDocumentStates.Approved &&
    stepData?.stepTwo === PrekapiDocumentStates.Approved &&
    stepData?.stepThree === PrekapiDocumentStates.Approved
  )
    return true;
  return false;
}
