import React from "react";
import { CardResumeClient } from "../PrequalifiedClients/CardResumeClient";

export const PotentialClientsActions = ({
    informationClient,
    resumeData
}: any) => {
    return (
        <CardResumeClient
            informationClient={informationClient}
            resumeData={resumeData}
        />
    )
}