import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      paddingTop: '10px',
      paddingBottom: '10px',
      // padding: theme.spacing(2, 4, 3),
    },
    paddings: {
      padding: theme.spacing(1, 4, 1, 4),
    },
    textBox: {
      width: '100%',
      margin: '10px auto',
    },
  })
);

interface ConclusionBodyProps {
  counterPhotosStatus: string[];
}

export const ConclusionBody = ({
  counterPhotosStatus,
}: ConclusionBodyProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography
        id='transition-modal-description'
        variant='h4'
        align='center'
        className={classes.paddings}
        color='textPrimary'
      >
        ¡Validación completada!
      </Typography>
      {counterPhotosStatus.map((status) => (
        <Typography
          id='transition-modal-description'
          variant='body1'
          className={classes.paddings}
          color='textPrimary'
        >
          {status}
        </Typography>
      ))}
    </>
  );
};
