import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import OutlinedCard from '../Information/OutlinedCard';
import { QualificationEnum } from '../../../Utils/enums';
import { QualificationInformationModel } from '../../../Utils/models/ClientInformationModel';

export const CardResumeClient = ({ informationClient, resumeData }: any) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
  }));
  const classes = useStyles();
  const clientInformation = new QualificationInformationModel(
    informationClient,
    QualificationEnum.CLIENT
  );
  const [qualificationResume, setQualificationResume] =
    useState<QualificationInformationModel>(
      new QualificationInformationModel(
        resumeData,
        QualificationEnum.QUALIFICATION
      )
    );
  useEffect(() => {
    if (resumeData) {
      setQualificationResume(
        new QualificationInformationModel(
          resumeData,
          QualificationEnum.QUALIFICATION
        )
      );
    }
  }, [resumeData]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <OutlinedCard
            title={clientInformation.title}
            properties={clientInformation.properties}
            hasButton={false}
            rawData={informationClient}
          />
        </Grid>
        <Grid item xs={6}>
          <OutlinedCard
            title={qualificationResume.title}
            properties={qualificationResume.properties}
            hasButton={true}
            rawData={resumeData}
            isPreapprovedClient={resumeData?.isPreapprovedClient || false}
          />
        </Grid>
      </Grid>
    </div>
  );
};
