import React, { useEffect } from 'react';
import { Button, useRefresh } from 'react-admin';
import { useState } from 'react';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import { Dialog } from '@material-ui/core';
import Cookies from '../../../Utils/cookies';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Button as ButtonUI } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { RequestLink } from '../../../types/PrekapiDocuments/PrekapiDocuments';
import { isDate } from '../../../Utils/DateUtils';

const useStyles = makeStyles({
  button1: {
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '0px',
    height: '28px',
  },
  button2: {
    borderRadius: '0px',
    border: '2px solid #a6a2a1',
    height: '28px',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  border: {
    borderRadius: '40px',
  },
  right: {
    display: 'flex',
    justifyContent: 'right',
  },
  title: {
    marginBottom: '30px',
  },
  status: {
    marginTop: '40px',
    marginLeft: '40px',
  },
  close: {
    display: 'flex',
    justifyContent: 'right',
  },
});

export const UpdateLink = ({
  expirationDate,
  claimId,
}: {
  expirationDate?: Date;
  claimId?: string;
}) => {
  const token = Cookies.getCookie('token');
  const [error, setError] = useState(null);
  const classes = useStyles();

  const [showModal, setShowModal] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const refresh = useRefresh();

  useEffect(() => {
    const today = new Date();
    if (
      (expirationDate != null || expirationDate != undefined) &&
      today >= expirationDate
    ) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  });

  async function getStatusLink() {
    const request = await fetch(
      `${process.env.REACT_APP_API_URL}/customerrequestlinks/model/${claimId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    const data = await request.json();
    if (!request.ok) throw new Error(data);

    const parsedDate: RequestLink = data;
    return parsedDate;
  }

  function validOrNotValid(date: string): String {
    if (date === undefined) {
      console.error('date is not found');
    } else {
      const dateLink = new Date(date);
      const today = new Date();
      if (dateLink < today) {
        return 'Expirado';
      } else {
        return 'Valido';
      }
    }
    return date;
  }

  const handleOpen = (event) => {
    event.stopPropagation();
    setShowModal(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setShowModal(false);
  };

  const handleStatusValid = (date: String): String => {
    if (date === undefined) {
      console.error('Date undefined');
    } else {
      return isDate(date);
    }
    return date + '';
  };

  const handleFetchLink = async (event) => {
    event.stopPropagation();
    setError(null);
    setShowModal(false);
    try {
      const { requestLinkId, solicitud } = await getStatusLink();
      const updatedLink = await fetch(
        `${process.env.REACT_APP_API_URL}/customerrequestlinks`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            requestLinkId: requestLinkId,
            solicitud: solicitud?.id,
          }),
        }
      );
      refresh();
    } catch (error) {
      console.error(error);
    }
  };

  function enabledOrNot(date: string): boolean {
    if (date === undefined) {
      return true;
    } else {
      const dateLink = new Date(date);
      const today = new Date();
      if (dateLink < today) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  return (
    <div>
      {!showButton && (
        <div>
          <Button onClick={handleOpen}>
            <LinkRoundedIcon style={{ color: 'black' }} />
          </Button>
          <Dialog
            open={showModal}
            onClose={setShowModal}
            disableBackdropClick={false}
            maxWidth='xs'
            fullWidth
          >
            <div style={{ border: '2px solid #a6a2a1', borderRadius: '5px' }}>
              <Card>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                    borderBottom: '2px solid  #a6a2a1',
                    padding: '10px',
                  }}
                >
                  <Typography variant='h4'>
                    <b>Renovar enlace</b>
                  </Typography>
                  <div onClick={handleClose}>
                    <CloseIcon style={{ color: ' #a6a2a1' }} />
                  </div>
                </div>
                <CardContent>
                  <Typography variant='h5' className={classes.center}>
                    ¿Está seguro de renovar el enlace de acceso?
                  </Typography>
                  <Typography variant='h5' className={classes.center}>
                    Se le notificará al usuario su nuevo enlace
                  </Typography>
                  <div className={classes.status}>
                    <Typography variant='h6' component='p'>
                      Estatus del enlace actual:{' '}
                      {validOrNotValid(expirationDate + '')}
                    </Typography>
                    <Typography variant='h6' component='p'>
                      Fecha de vencimiento:{' '}
                      {handleStatusValid(expirationDate + '')}
                    </Typography>
                  </div>
                </CardContent>
                <div style={{ borderTop: '2px solid #a6a2a1' }}>
                  <CardActions className={classes.right}>
                    <ButtonUI onClick={handleClose} className={classes.button1}>
                      Cancelar
                    </ButtonUI>
                    <ButtonUI
                      onClick={handleFetchLink}
                      className={classes.button2}
                      disabled={enabledOrNot(expirationDate + '')}
                    >
                      Renovar
                    </ButtonUI>
                  </CardActions>
                </div>
              </Card>
            </div>
          </Dialog>
        </div>
      )}
    </div>
  );
};
