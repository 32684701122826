import React, { useContext, useEffect } from 'react';
import { FilesTablePotentialClients } from '../FilesTable';
import { DataTableStep } from '../DataTableStep';
import { StepThree } from '../../../types/PrekapiDocuments/PrekapiDocuments';
import { ApproveRejectFileContext } from '../../../contexts/PotentialClients/ApproveRejectFileContext';
import {
  PrekapiDocumentStates,
  TypeFilePrekapi,
} from '../../../Constants/PotentialClients';

interface TableFilesSyntageProps {
  stepThree?: StepThree;
  isOnReview?: boolean;
}

export const TableFilesSyntage = ({
  stepThree,
  isOnReview = false,
}: TableFilesSyntageProps) => {
  const {
    syntage,
    setShowModal,
    setButtonText,
    setContainerText,
    setTitle,
    setNewStatus,
    setSyntage,
  } = useContext(ApproveRejectFileContext);
  useEffect(() => {
    setSyntage({
      firstCommentary: stepThree?.commentaries,
      fileOneStatus: stepThree?.status,
    });
  }, [stepThree]);
  return (
    <div style={{ marginTop: '10px' }}>
      <FilesTablePotentialClients>
        <DataTableStep
          id={stepThree?.id}
          typeFilePrekapi={TypeFilePrekapi.Syntage}
          comments={syntage?.firstCommentary}
          loadedDate={stepThree?.uploadDate?.toString()}
          contentUrl={stepThree?.url}
          isOnReview={isOnReview}
          showOnReject={(isReject) => {
            setShowModal(isReject);
            setTitle('Paso 3: Syntage');
            setContainerText(
              `¿Está seguro de que desea rechazar el archivo ${
                stepThree?.fileName ?? 'Syntage'
              }?`
            );
            setButtonText('Rechazar');
            setNewStatus(PrekapiDocumentStates.Rejected);
          }}
          showOnApprove={(isApprove) => {
            setShowModal(isApprove);
            setTitle('Paso 3: Fotografías de la Garantía');
            setContainerText(
              `¿Está seguro de que desea aprobar el archivo ${
                stepThree?.fileName ?? 'Syntage'
              }?`
            );
            setButtonText('Aprobar');
            setNewStatus(PrekapiDocumentStates.Approved);
          }}
          status={syntage?.fileOneStatus}
        />
      </FilesTablePotentialClients>
    </div>
  );
};
