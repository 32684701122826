import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { AuthRole } from '../../Utils/enums';
import { AuthRoles } from '../../Constants/AuthRoles';

interface ActionsFilesProps {
  endpoint?: string;
  onReview?: (isOnReview: boolean) => void;
}

export const ActionsFiles = ({ endpoint, onReview }: ActionsFilesProps) => {
  const [isOnReview, setIsOnReview] = useState(false);
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const [hasPermissionChangeStatus, setHasPermissionChangeStatus] =
    useState(false);
  useEffect(() => {
    setHasPermissionChangeStatus(
      [AuthRole.Admin, AuthRoles.Agent].includes(user.role.id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuthRole, user]);
  return (
    <Button
      variant='contained'
      onClick={() => {
        onReview?.(!isOnReview);
        setIsOnReview(!isOnReview);
      }}
      disabled={!hasPermissionChangeStatus}
    >
      En Revision
    </Button>
  );
};
