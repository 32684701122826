import { upperCase } from 'lodash';
import { QualificationEnum } from '../enums';
import { isDate } from '../DateUtils';

export class QualificationInformationModel {
  title: String;
  properties: PropertiesModel[];
  hasButton: boolean = false;
  rawData: any;
  isPreapprovedClient?: boolean = false;

  constructor(data: any, enumType: QualificationEnum) {
    if (data === null || data === undefined) {
      //throw ("Fail Data Processor in QualificationInformationModel")
    }
    this.rawData = data;
    switch (enumType) {
      case QualificationEnum.CLIENT: {
        this.title = 'Cliente';
        this.properties = setInformationClient(data);
        break;
      }
      case QualificationEnum.QUALIFICATION: {
        this.title = 'Resumen de la Precalificación';
        this.properties = setResumeQualification(data);
        break;
      }
      default: {
        this.properties = [];
        break;
      }
    }
  }
}

function setInformationClient(data: any): PropertiesModel[] {
  return [
    new PropertiesModel('Nombre', notInfo(data?.name)),
    new PropertiesModel('Tipo de Persona', data?.personType as String),
    new PropertiesModel('Email', data?.email as String),
    new PropertiesModel('Teléfono', data?.phone as String),
    new PropertiesModel('Asesor de venta digital', notInfo(data?.agentName)),
  ];
}

function setResumeQualification(data: any): PropertiesModel[] {
  return [
    new PropertiesModel(
      'Estatus del enlace',
      isBoolean(notInfo(data?.linkStatus))
    ),
    new PropertiesModel(
      'Fecha de vencimiento',
      isDate(notInfo(data?.linkDueDate))
    ),
    new PropertiesModel('Fotos de la garantía', status(notInfo(data?.stepOne))),
    new PropertiesModel('Buró de crédito', status(notInfo(data?.stepTwo))),
    new PropertiesModel('Syntage', status(notInfo(data?.stepThree))),
  ];
}

function notInfo(field: any) {
  return field === null || field === undefined
    ? 'Sin Información'
    : (field as String);
}

function isBoolean(field: any) {
  if (typeof field == 'boolean') {
    if (field === true) {
      return 'Expirado';
    } else {
      return 'Valido';
    }
  }
  return field;
}

function status(field: any) {
  var checkStatus = field;
  switch (upperCase(field)) {
    case 'SENT': {
      checkStatus = TranslateEmailStatus.SENT;
      break;
    }
    case 'TOSEND': {
      checkStatus = TranslateEmailStatus.TOSEND;
      break;
    }
    case 'APPROVED': {
      checkStatus = TranslateEmailStatus.APPROVED;
      break;
    }
    case 'RECEIVED': {
      checkStatus = TranslateEmailStatus.RECEIVED;
      break;
    }
    case 'REJECTED': {
      checkStatus = TranslateEmailStatus.REJECTED;
      break;
    }
    case 'ONREVIEW': {
      checkStatus = TranslateEmailStatus.ONREVIEW;
      break;
    }
    case 'INREVISION': {
      checkStatus = TranslateEmailStatus.INREVISION;
      break;
    }
    case 'ERROR': {
      checkStatus = TranslateEmailStatus.ERROR;
      break;
    }
    case 'UNKNOWN': {
      checkStatus = TranslateEmailStatus.UNKNOWN;
      break;
    }
    default: {
      checkStatus = field;
      break;
    }
  }
  return checkStatus;
}

export enum TranslateEmailStatus {
  SENT = 'ENVIADO',
  TOSEND = 'ENVIAR',
  APPROVED = 'APROBADO',
  RECEIVED = 'RECIBIDO',
  REJECTED = 'RECHAZADO',
  ONREVIEW = 'EN REVISIÓN',
  INREVISION = 'EN REVISIÓN',
  ERROR = 'ERROR',
  UNKNOWN = 'DESCONOCIDO',
}

class PropertiesModel {
  name: String;
  value: String;

  constructor(name: String, value: String) {
    this.name = name;
    this.value = value;
  }
}
