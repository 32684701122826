import React, { useContext, useState } from 'react';
import {
  Button,
  Divider,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ApproveRejectFileContext } from '../../contexts/PotentialClients/ApproveRejectFileContext';
import { WhichFileState } from '../../Constants/PotentialClients';
import {
  getDocumentationStatusValidation,
  updatePrekapiDocument,
} from '../../Services/PrekapiDocuments';
import { DocumentationStatusValidation } from '../../types/PrekapiDocuments/PrekapiDocuments';
import { ConclusionBody } from '../ConclusionBody';

interface ApproveRejectFileModalProps {
  open?: boolean;
  id?: string;
  title?: string;
  text?: string;
  textActionButton?: string;
  newStatus?: string;
  claimId?: string;
  clientId?: string;
  setOpen: (isOpen: boolean) => void;
  setDataSteps: (data: DocumentationStatusValidation) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      paddingTop: '10px',
      paddingBottom: '10px',
      // padding: theme.spacing(2, 4, 3),
    },
    paddings: {
      padding: theme.spacing(1, 4, 1, 4),
    },
    textBox: {
      width: '100%',
      margin: '10px auto',
    },
  })
);

export const ApproveRejectFileModal = ({
  open = false,
  id,
  title,
  text,
  textActionButton,
  newStatus,
  setOpen,
  claimId,
  clientId,
  setDataSteps,
}: ApproveRejectFileModalProps) => {
  const [commentaries, setCommentaries] = useState('');
  const {
    whichFileIsBeingUpdated,
    warrantyPhotos,
    setWarrantyPhotos,
    setCreditBureau,
    setSyntage,
    setWhichFileIsBeingUpdated,
    newStatus: newStatusContext,
    setShowModal,
    setWasUpdated,
    isModalConclusion,
    setIsModalConclusion,
    counterPhotosStatus,
  } = useContext(ApproveRejectFileContext);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const updatedStatus = async () => {
    try {
      setLoading(true);
      await updatePrekapiDocument({
        id,
        status: newStatusContext,
        commentaries,
      });
      const steps = await getDocumentationStatusValidation(
        clientId || '',
        claimId || ''
      );
      setDataSteps(steps);
      if (isModalConclusion) {
        localStorage.removeItem('showConclusionModal');
      }
      if (whichFileIsBeingUpdated === WhichFileState.PhotoFileOne) {
        setWarrantyPhotos({
          ...warrantyPhotos,
          fileOneStatus: newStatus,
          firstCommentary: commentaries,
        });
      }
      if (whichFileIsBeingUpdated === WhichFileState.PhotoFileTwo) {
        setWarrantyPhotos({
          ...warrantyPhotos,
          fileTwoStatus: newStatus,
          secondCommentary: commentaries,
        });
      }
      if (whichFileIsBeingUpdated === WhichFileState.PhotoFileThree) {
        setWarrantyPhotos({
          ...warrantyPhotos,
          fileThreeStatus: newStatus,
          thirdCommentary: commentaries,
        });
      }
      if (whichFileIsBeingUpdated === WhichFileState.PhotoFileFour) {
        setWarrantyPhotos({
          ...warrantyPhotos,
          fileFourStatus: newStatus,
          fourthCommentary: commentaries,
        });
      }
      if (whichFileIsBeingUpdated === WhichFileState.BureauFileOne) {
        setCreditBureau({
          fileOneStatus: newStatus,
          firstCommentary: commentaries,
        });
      }
      if (whichFileIsBeingUpdated === WhichFileState.SyntageFileOne) {
        setSyntage({
          fileOneStatus: newStatus,
          firstCommentary: commentaries,
        });
      }
      setCommentaries('');
      setWhichFileIsBeingUpdated('');
      setShowModal(false);
      setLoading(false);
      setIsModalConclusion(false);
      // setFileId('');
    } catch (error) {
      console.error({ error });
      setIsModalConclusion(false);
      setLoading(false);
      setWasUpdated(false);
    }
  };
  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography
            id='transition-modal-title'
            variant='h4'
            component='h2'
            color='textPrimary'
            className={classes.paddings}
          >
            {title}
          </Typography>
          <Divider variant='fullWidth' />
          {isModalConclusion ? (
            <ConclusionBody counterPhotosStatus={counterPhotosStatus} />
          ) : (
            <>
              <Typography
                id='transition-modal-description'
                variant='body1'
                className={classes.paddings}
                color='textPrimary'
              >
                {text}
              </Typography>
              <Grid
                container
                direction='row'
                justifyContent='center'
                style={{ width: '100%' }}
              >
                <Grid item xs={11}>
                  <TextField
                    id='outlined-multiline-static'
                    label='Comentarios'
                    multiline
                    rows={4}
                    defaultValue=''
                    value={commentaries}
                    variant='outlined'
                    fullWidth
                    onChange={(e) => {
                      setCommentaries(e.target.value);
                    }}
                    className={classes.textBox}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='flex-end'
            spacing={2}
          >
            <Grid item>
              <Button
                disabled={loading}
                style={{ background: '#FA4032' }}
                onClick={() => {
                  setOpen(false);
                  setWhichFileIsBeingUpdated('');
                  setCommentaries('');
                  if (isModalConclusion) {
                    localStorage.removeItem('showConclusionModal');
                  }
                }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={updatedStatus}
                disabled={loading || !commentaries}
                variant='contained'
              >
                {textActionButton}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};
