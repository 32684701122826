import React, { useContext, useEffect, useState } from 'react';
import { FilesTablePotentialClients } from '../FilesTable';
import {
  DocumentationStatusValidation,
  StepOne,
  StepThree,
} from '../../../types/PrekapiDocuments/PrekapiDocuments';
import { DataTableStep } from '../DataTableStep';
import { ApproveRejectFileContext } from '../../../contexts/PotentialClients/ApproveRejectFileContext';
import { PrekapiDocumentStates } from '../../../Constants/PotentialClients';
import {
  canShowModal,
  howManyAreThereInStepOne,
} from '../../../Utils/PotentialClients/StepsFiles';
import { ShowFullPhoto } from '../ShowFullPhoto';
import { FilesConclusionModal } from '../FilesConclusionModal';
import {
  approveAllStepOne,
  getDocumentationStatusValidation,
  updatePrekapiDocument,
} from '../../../Services/PrekapiDocuments';

interface TableWarrantyPhotosProps {
  stepOne?: StepOne;
  isOnReview?: boolean;
  claimId?: string;
  clientId?: string;
  setDataSteps: (steps?: DocumentationStatusValidation) => void;
}

export const TableWarrantyPhotos = ({
  stepOne,
  isOnReview = false,
  setDataSteps,
  claimId,
  clientId,
}: TableWarrantyPhotosProps) => {
  const {
    setShowModal,
    setButtonText,
    setContainerText,
    setTitle,
    setNewStatus,
  } = useContext(ApproveRejectFileContext);
  const [finalConclusion, setFinalConclusion] = useState({
    result: 'En revision',
    commentaries: '',
  });
  const [counterChangedTotal, setCounterChangedTotal] = useState(0);
  const [showConclusionModal, setShowConclusionModal] = useState(false);
  const [textCountersStatus, setTextCountersStatus] = useState<string[]>([]);
  const [, setTrackerLastChanged] = useState<StepThree>();
  useEffect(() => {
    const { approvedCounter, rejectedCounter, status } =
      howManyAreThereInStepOne(stepOne);
    const totalCountStatus = approvedCounter + rejectedCounter;
    if (
      totalCountStatus === 4 &&
      !localStorage.getItem('showConclusionModal') &&
      !localStorage.getItem('noShowAgainModal')
    ) {
      localStorage.setItem('showConclusionModal', '1');
    }
    setCounterChangedTotal(totalCountStatus);
    setTextCountersStatus([
      `• ${approvedCounter} Aprobado${approvedCounter > 1 ? 's' : ''}`,
      `• ${rejectedCounter} Rechazado${rejectedCounter > 1 ? 's' : ''}`,
    ]);
    setFinalConclusion({
      result: status,
      commentaries: `<p>${stepOne?.firstFile?.commentaries ?? ''}<br/>${
        stepOne?.secondFile?.commentaries ?? ''
      }<br/>${stepOne?.thirdFile?.commentaries ?? ''}<br/>${
        stepOne?.fourthFile?.commentaries ?? ''
      }</p>`,
    });
  }, [stepOne]);
  const handleUpdateConclusion = async (finalCommentaries: string) => {
    try {
      await approveAllStepOne(
        stepOne?.conclusion?.trackedLastId || '',
        finalCommentaries,
        claimId
      );
      const steps = await getDocumentationStatusValidation(
        clientId || '',
        claimId || ''
      );
      setDataSteps(steps);
    } catch (error) {
      console.error({ error });
    }
  };
  useEffect(() => {
    if (counterChangedTotal === 4 && canShowModal(stepOne?.conclusion)) {
      setShowConclusionModal(true);
      return;
    }
    setShowConclusionModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterChangedTotal, stepOne]);

  return (
    <>
      <ShowFullPhoto />
      <FilesConclusionModal
        counterPhotosStatus={textCountersStatus}
        setOpen={setShowConclusionModal}
        open={showConclusionModal}
        handleCancelStatus={async () => {
          localStorage.removeItem('showConclusionModal');
          localStorage.removeItem('noShowAgainModal');
          try {
            await updatePrekapiDocument({
              id: stepOne?.conclusion?.trackedLastId || '',
              status: PrekapiDocumentStates.Received,
              commentaries: '',
            });
            const steps = await getDocumentationStatusValidation(
              clientId || '',
              claimId || ''
            );
            setDataSteps(steps);
          } catch (error) {
            console.error({ error });
          }
        }}
        handleCompleteStatus={(finalCommentaries) => {
          handleUpdateConclusion(finalCommentaries);
          localStorage.setItem('noShowAgainModal', '1');
          setTrackerLastChanged(undefined);
          setShowConclusionModal(false);
        }}
      />
      <FilesTablePotentialClients
        showFooter={true}
        validationResult={finalConclusion.result}
        commentaries={stepOne?.conclusion?.conclusion || ''}
      >
        <DataTableStep
          id={stepOne?.firstFile?.id}
          comments={stepOne?.firstFile?.commentaries}
          loadedDate={stepOne?.firstFile?.uploadDate?.toString()}
          contentUrl={stepOne?.firstFile?.url}
          isOnReview={isOnReview}
          showOnReject={(isReject) => {
            setShowModal(isReject);
            setTitle('Paso 1: Fotografías de la Garantía');
            setContainerText(
              `¿Está seguro de que desea rechazar el archivo ${
                stepOne?.firstFile?.fileName ?? ''
              }?`
            );
            setButtonText('Rechazar');
            setNewStatus(PrekapiDocumentStates.Rejected);
            setTrackerLastChanged(stepOne?.firstFile);
            localStorage.removeItem('noShowAgainModal');
          }}
          showOnApprove={(isApprove) => {
            setShowModal(isApprove);
            setTitle('Paso 1: Fotografías de la Garantía');
            setContainerText(
              `¿Está seguro de que desea aprobar el archivo ${
                stepOne?.firstFile?.fileName ?? ''
              }?`
            );
            setButtonText('Aprobar');
            setNewStatus(PrekapiDocumentStates.Approved);
            setTrackerLastChanged(stepOne?.firstFile);
            localStorage.removeItem('noShowAgainModal');
          }}
          status={stepOne?.firstFile?.status}
        />
        <DataTableStep
          id={stepOne?.secondFile?.id}
          comments={stepOne?.secondFile?.commentaries}
          loadedDate={stepOne?.secondFile?.uploadDate?.toString()}
          contentUrl={stepOne?.secondFile?.url}
          isOnReview={isOnReview}
          showOnReject={(isReject) => {
            setShowModal(isReject);
            setTitle('Paso 1: Fotografías de la Garantía');
            setContainerText(
              `¿Está seguro de que desea rechazar el archivo ${
                stepOne?.secondFile?.fileName ?? ''
              }?`
            );
            setButtonText('Rechazar');
            setNewStatus(PrekapiDocumentStates.Rejected);
            setTrackerLastChanged(stepOne?.secondFile);
            localStorage.removeItem('noShowAgainModal');
          }}
          showOnApprove={(isApprove) => {
            setShowModal(isApprove);
            setTitle('Paso 1: Fotografías de la Garantía');
            setContainerText(
              `¿Está seguro de que desea aprobar el archivo ${
                stepOne?.secondFile?.fileName ?? ''
              }?`
            );
            setButtonText('Aprobar');
            setNewStatus(PrekapiDocumentStates.Approved);
            setTrackerLastChanged(stepOne?.secondFile);
            localStorage.removeItem('noShowAgainModal');
          }}
          status={stepOne?.secondFile?.status}
        />
        <DataTableStep
          id={stepOne?.thirdFile?.id}
          comments={stepOne?.thirdFile?.commentaries}
          loadedDate={stepOne?.thirdFile?.uploadDate?.toString()}
          contentUrl={stepOne?.thirdFile?.url}
          isOnReview={isOnReview}
          showOnReject={(isReject) => {
            setShowModal(isReject);
            setTitle('Paso 1: Fotografías de la Garantía');
            setContainerText(
              `¿Está seguro de que desea rechazar el archivo ${
                stepOne?.thirdFile?.fileName ?? ''
              }?`
            );
            setButtonText('Rechazar');
            setNewStatus(PrekapiDocumentStates.Rejected);
            setTrackerLastChanged(stepOne?.thirdFile);
            localStorage.removeItem('noShowAgainModal');
          }}
          showOnApprove={(isApprove) => {
            setShowModal(isApprove);
            setTitle('Paso 1: Fotografías de la Garantía');
            setContainerText(
              `¿Está seguro de que desea aprobar el archivo ${
                stepOne?.thirdFile?.fileName ?? ''
              }?`
            );
            setButtonText('Aprobar');
            setNewStatus(PrekapiDocumentStates.Approved);
            setTrackerLastChanged(stepOne?.thirdFile);
            localStorage.removeItem('noShowAgainModal');
          }}
          status={stepOne?.thirdFile?.status}
        />
        <DataTableStep
          id={stepOne?.fourthFile?.id}
          comments={stepOne?.fourthFile?.commentaries}
          loadedDate={stepOne?.fourthFile?.uploadDate?.toString()}
          contentUrl={stepOne?.fourthFile?.url}
          isOnReview={isOnReview}
          showOnReject={(isReject) => {
            setShowModal(isReject);
            setTitle('Paso 1: Fotografías de la Garantía');
            setContainerText(
              `¿Está seguro de que desea rechazar el archivo ${
                stepOne?.fourthFile?.fileName ?? ''
              }?`
            );
            setButtonText('Rechazar');
            setNewStatus(PrekapiDocumentStates.Rejected);
            setTrackerLastChanged(stepOne?.fourthFile);
            localStorage.removeItem('noShowAgainModal');
          }}
          showOnApprove={(isApprove) => {
            setShowModal(isApprove);
            setTitle('Paso 1: Fotografías de la Garantía');
            setContainerText(
              `¿Está seguro de que desea aprobar el archivo ${
                stepOne?.fourthFile?.fileName ?? ''
              }?`
            );
            setButtonText('Aprobar');
            setNewStatus(PrekapiDocumentStates.Approved);
            setTrackerLastChanged(stepOne?.fourthFile);
            localStorage.removeItem('noShowAgainModal');
          }}
          status={stepOne?.fourthFile?.status}
        />
      </FilesTablePotentialClients>
    </>
  );
};
