import React, { useState } from 'react';
import {
  Button,
  Divider,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

interface FilesConclusionModalProps {
  open?: boolean;
  id?: string;
  title?: string;
  newStatus?: string;
  counterPhotosStatus: string[];
  setOpen: (isOpen: boolean) => void;
  handleCompleteStatus: (commentaries: string) => void;
  handleCancelStatus: (isCancel: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      paddingTop: '10px',
      paddingBottom: '10px',
      // padding: theme.spacing(2, 4, 3),
    },
    paddings: {
      padding: theme.spacing(1, 4, 1, 4),
    },
    textBox: {
      width: '100%',
      margin: '10px auto',
    },
  })
);

export const FilesConclusionModal = ({
  open = false,
  title,
  setOpen,
  counterPhotosStatus,
  handleCancelStatus,
  handleCompleteStatus,
}: FilesConclusionModalProps) => {
  const classes = useStyles();
  const [commentaries, setCommentaries] = useState('');
  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography
            id='transition-modal-title'
            variant='h4'
            component='h2'
            color='textPrimary'
            className={classes.paddings}
          >
            {title}
          </Typography>
          <Divider variant='fullWidth' />
          <Typography
            id='transition-modal-description'
            variant='h4'
            align='center'
            className={classes.paddings}
            color='textPrimary'
          >
            ¡Validación completada!
          </Typography>
          {counterPhotosStatus.map((status) => (
            <Typography
              id='transition-modal-description'
              variant='body1'
              className={classes.paddings}
              color='textPrimary'
            >
              {status}
            </Typography>
          ))}
          <Grid
            container
            direction='row'
            justifyContent='center'
            style={{ width: '100%' }}
          >
            <Grid item xs={11}>
              <TextField
                id='outlined-multiline-static'
                label='Comentarios'
                multiline
                rows={4}
                defaultValue=''
                value={commentaries}
                variant='outlined'
                fullWidth
                onChange={(e) => {
                  setCommentaries(e.target.value);
                }}
                className={classes.textBox}
              />
            </Grid>
          </Grid>
          <Divider variant='fullWidth' />
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='flex-end'
            spacing={2}
            style={{
              marginTop: '10px',
            }}
          >
            <Grid item>
              <Button
                style={{ background: '#FA4032' }}
                onClick={() => {
                  setOpen(false);
                  handleCancelStatus(true);
                  setCommentaries('');
                }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setOpen(true);
                  handleCompleteStatus(commentaries);
                  setCommentaries('');
                }}
                variant='contained'
                disabled={!commentaries}
              >
                Concluir
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};
