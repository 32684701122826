import React from 'react';
import { PrekapiDocumentStates } from '../../Constants/PotentialClients';
import { Typography } from '@material-ui/core';

interface LabelApproveRejectProps {
  status?: string;
}

export const LabelApproveReject = ({ status }: LabelApproveRejectProps) => {
  if (PrekapiDocumentStates.Approved === status) {
    return (
      <Typography variant='h6' style={{ color: '#5CB338', fontWeight: 'bold' }}>
        Aprobado
      </Typography>
    );
  }
  if (PrekapiDocumentStates.Rejected === status) {
    return (
      <Typography variant='h6' style={{ color: '#FA4032', fontWeight: 'bold' }}>
        Rechazado
      </Typography>
    );
  }
  return null;
};
