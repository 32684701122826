const AWS = require('aws-sdk');

export async function getInstance() {
  const s3 = new AWS.S3();
  AWS.config.update({
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  return s3;
}

export async function getObject(contentUrl: any) {
  const params = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Key: contentUrl,
  };
  try {
    const s3 = await getInstance();
    const fileFromAWS = await s3.getObject(params).promise();
    return fileFromAWS;
  } catch (error) {
    console.error({ error });

    return '';
  }
}

export async function getObjectPrekapiDocs(contentUrl: any) {
  var pathname = new URL(contentUrl).pathname;
  const key = decodeURIComponent(pathname.slice(1));
  const params = {
    Bucket: process.env.REACT_APP_BUCKET_NAME_KAPI_DOCS,
    Key: key,
  };
  try {
    const s3 = await getInstance();
    return s3.getSignedUrlPromise("getObject", {
      Bucket: process.env.REACT_APP_BUCKET_NAME_KAPI_DOCS,
      Key: key,
      Expires: 60 * 60,
    });
  } catch (error) {
    console.log("Catch getObjectPrekapiDocs", error)
    console.error({ error });

    return '';
  }
}
